import React from "react"
import { Layout } from "../components/common"
import Gothic from "../components/Gothic"
import { Helmet } from "react-helmet"
const gothic = () => {
  return (
    <Layout>
      <Helmet>
        <title>Gothic</title>
      </Helmet>
      <Gothic />
    </Layout>
  )
}

export default gothic
