import React, { useState } from "react"
import bandWave from "../assets/images/bg/banner4-wave1.svg"
import waterMark2 from "../assets/images/bg/watermark2-bg.png"
import waterMark3 from "../assets/images/bg/watermark1-bg.png"
import gothic from "../../assets/illustrations/DemonCity.jpeg"
import { Link } from "react-scroll"

const info = [
  {
    title: "Demon City",
    info:
      "Traditional Gothic fiction breaking new ground takes the reader to Hell with Rhea pursued by her family and Demonic beings. A life of homelessness and hardships in Demon City, amodern-day metropolis on earth, ensures Rhea’s journey to the Inferno and the Son of Satan." +
      "/p" +
      "When the Booke of Anthaca is stolen from a ruined Jesuit Church in Demon City, all Hell enters chaos. A further mystery is that translated fragments begin to appear online. The Booke of Anthaca in horrifying language is the prophesy of the Lost Apostle of Light and Darkness, Good and Evil. The Booke of Anthaca contains the refutation of all revelation and asserts pure evil as reality." +
      "/p" +
      "Sesamon, a Loner Demon fatigued from consuming flesh of world leaders, politicians, and clerics, longs to return to Hell. Pultazaro, a Drooling Demon, head of the Society for Preservation of Demons (SPDs) has the agenda to capture Rhea the Slayer." +
      "/p" +
      "Jonah, the disfigured cleric after a vision of Rhea, sets out to save her. The Goddess Seka and Father Finyatto arrive in Demon City and the prophesy of the Booke of Anthaca begins to unravel. Their connection to the Slayer, Carn-Lee in captivity in Hell is exposed." +
      "/p" +
      "The action-driven plot features the supernatural, the grotesque and the added factor of The Booke of Anthaca which parallels the text. Demon City is written to provide a cathartic response within its feminist plea.",
  },
]
const Index = () => {
  const [showInfo, setShowInfo] = useState(false)
  return (
    <>
      <div className="banner-section4" style={{ minHeight: "60vh" }}>
        <img src={bandWave} className="banner4-wave1 img-fluid" alt="Wave" />
        <img
          src={waterMark2}
          className="banner4-wave2 img-fluid"
          alt="Wave 1"
        />

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div
                className="section-title3 primary4 text-cener"
                style={{ marginBottom: "12px" }}
              >
                <span>-SPA Cottage Gothic-</span>
                <p>Browse the latest gothic offering from SPA Cottage</p>

                <div className="button-group gap-5">
                  <Link
                    to="classicsCollection"
                    smooth={true}
                    offset={-110}
                    duration={100}
                    className="eg-btn btn--primary4 btn--lg"
                    style={{ cursor: "pointer" }}
                  >
                    View Collection
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="team-section2 pb-120 position-relative overflow-hidden"
        id="classicsCollection"
      >
        <img src={waterMark3} className="watermark1-bg" alt="watermark" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10">
              <div className="section-title3 primary4 text-cener">
                <span style={{ marginTop: "10px" }}>-Our Collection-</span>
                <p>Browse the latest in SPA Cottage Gothic</p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div
                className="single-team2 style-2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="1s"
              >
                <div className="team-image">
                  <img src={gothic} alt="book" />
                  <div className="social-area gap-3">
                    <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                      <li>
                        <a
                          href="https://www.amazon.co.uk/dp/B0BB4GL3SN"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bx bxl-amazon"></i>
                        </a>
                     
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                  <h4 className="name">Demon City</h4>
                  <div
                    className="section-title3 primary4 text-cener"
                    style={{
                      marginBottom: "0px",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <a
                      href="https://www.amazon.co.uk/dp/B0BB4GL3SN"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>Purchase</span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
